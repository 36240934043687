function tabsSlider() {
    let breakpointMobile = window.matchMedia('screen and (max-width: 767px)');
    let $tabs = $('.js-tabs-slider');

    $tabs.each(function(i, item) {
        let $tabBox = $(item),
            $tab = $tabBox.find('[data-toggle]'),
            $box = $tabBox.find('[data-id]'),
            $slider = $box.find('.swiper-container'),
            $tabActive = $tabBox.find('[data-toggle].is-active'),
            $line = $tabBox.find('[data-line]');

        $(window).on('load', function () {
            $line.width($tabActive.width() + 'px');
            $line.css('left', $tabActive.position().left + 'px');
        });

        if(!$slider.hasClass('swiper-container-initialized')) {
            $slider.each(function(i, slider) {
                const { swiper } = $(slider)[0];

                if (!swiper) {
                    return;
                }

                swiper.destroy(true, true);
            });
        }

        $tab.on('click', function(e) {
            e.preventDefault();

            let id = $(this).attr('data-toggle'),
                $boxActive = $tabBox.find('[data-id="'+ id +'"]'),
                $boxActiveSlider = $boxActive.find('.swiper-container');

            $line.width($(this).width() + 'px');
            $line.css('left', this.offsetLeft + 'px');

            $tab.removeClass('is-active');
            $(this).addClass('is-active');

            $box.removeClass('is-active');
            $boxActive.addClass('is-active');

            if($boxActiveSlider.length) {
                $boxActiveSlider[0].swiper.update();
            }

            if(breakpointMobile.matches) {
                var leftPosition = this.offsetLeft - ((window.innerWidth - this.offsetWidth) / 2);

                $tabBox.find('.tabs-slider__nav-list').animate({
                    scrollLeft: leftPosition
                }, 500);
            }

            setTimeout(function() {
                var offsetTop = window.pageYOffset;

                if (e.target.attributes.href === undefined) {
                    return null;
                }

                window.location.replace(e.target.attributes.href.value);
            }, 300)
        });
    });
}

export default tabsSlider;