import ymaps from 'ymaps';

function map() {
  const nodes = document.getElementsByClassName('js-map');

  if (!nodes.length) {
    return;
  }
  ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=3c90a8bd-57e0-42e9-9a71-cc166aad32f4').then(maps => {
    [].forEach.call(nodes, node => {
      const { zoom, coords, center } = node.dataset;
      const points = coords ? coords.split(' ').map(c => c.split(',')) : [];
      const mapCenter = center ? center.split(',') : points[0];
      const map = new maps.Map(node, {
        center: mapCenter,
        zoom: zoom || 10,
        controls: [],
      });

      points.forEach(coords => {
        const point = new maps.Placemark(coords, {}, {
          balloonShadow: false,
          balloonOffset: [-282, 8],
          iconLayout: 'default#image',
          iconImageHref:  node.dataset.placemark,
          iconImageSize: [40, 40],
          iconImageOffset: [-20, -40],
          iconImageClipRect: [[40, 40], [80, 0]],
          hideIconOnBalloonOpen: false,
        });

        map.geoObjects.add(point);
      });
    });
  });
}

export default map;
