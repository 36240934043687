import Vue from 'vue';
import axios from 'axios';

Vue.component('modal-product-sizes', {
    data() {
        return {
            id: null,
            m_showModalSizes: false,
            product_offers: gon.product_offers
        };
    },
    beforeMount() {
        this.uniqID();
    },
    mounted() {
        this.$root.$on('m_openProductSizes', () => {
            this.render();
        });
    },
    methods: {
        uniqID() {
            this.id = Math.random().toString(36).substr(2, 9);
        },
        render() {
            let _this = this,
                html = document.querySelector('html');

            this.m_showModalSizes = true;

            _this.$nextTick(function () {
                console.log(_this.id)
                html.classList.add('is-open-modal-'+_this.id);
                html.dataset.animation = 'slideOutLeft';
            });
        },
        changeColor(offer) {
            window.location.replace(`/products/${offer.slug}`)
        },
        close() {
            this.m_showModalSizes = false;

            let html = document.querySelector('html');

            html.classList.remove('is-open-modal-' + this.id);
            html.dataset.animation = 'slideInLeft';

            setTimeout(function() {
                html.removeAttribute('data-animation');
            }, 1000);
        },
    },
    template:
        `<transition name="slide-right" enter-active-class='is-animated slideInRight' leave-active-class='is-animated slideOutRight'>
            <div v-if="m_showModalSizes" class="popup-wrapper popup-modal">
                <div class="popup-wrapper__container">
                    <div class="popup-wrapper__content">
                        <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                        <div class="popup-top-panel" @click="close()">
                            <div class="popup-back-link"></div>
                            <div class="popup-top-panel__text">Выберите размер</div>
                        </div>
                        <div slot="body" class="popup popup_colors">
                            <div class="product-colors">
                                <div class="product-colors__item product-card-color" v-for="offer in product_offers" @click="changeColor(offer)">
                                    <div class="product-card-color__picture">
                                        <img :src="offer.image.thumb.url" alt=""/>
                                    </div>
                                    <div 
                                        class="product-card-color__info" 
                                        style="font-size: 14px;"
                                        v-if="offer.size"
                                    >
                                        Размер: {{ offer.size }}
                                    </div>
                                    <div class="product-card-color__info">
                                        <div class="product-card-color__name">{{ offer.title }}</div>
                                        <div class="product-card-color__price">{{ offer.price }} ₽</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>`,
});